@tailwind base;
@tailwind components;
@tailwind utilities;
ul {
  list-style-type: disc; 
  padding-left: 20px;   
}
.hoverText {
  position: relative;
}
.hoverText::after {
  width: 0%;
  background-color: white;
  transition: width 0.5s linear;
}
.hoverText:hover::after {
  width: 100%;
}
img {
  pointer-events: none;
}
.workShadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.25) 0px 4px 4px 0px, rgba(0, 0, 0, 0.25) 0px 8px 8px 0px,
    rgba(0, 0, 0, 0.25) 0px 16px 16px 0px, rgba(0, 0, 0, 0.25) 0px 32px 32px 0px;
}
.custom-work-hover img {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
.custom-work-hover:hover img {
  opacity: 0.68;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.custom-link-hover {
  background-color: white;
  transition: all 0.5s ease-in-out;
}
.custom-link-hover:hover {
  color: white;
  background-color: transparent;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  height: 40px;
  width: 40px;
  border-radius: 40px;
  z-index: 100;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 50%;
  max-width: 90px;
  border-radius: 50px;
  padding: 4px 0;
  bottom: 10px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet {
  background-color: white;
  width: 10px;
  height: 10px;
}

.custom-right-overlay {
  -webkit-mask-image: linear-gradient(
    to right,
    rgb(0, 0, 0) 0%,
    rgb(0, 0, 0) 12.5%,
    rgb(0, 0, 0) 87.5%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 50;
}
.custom-left-overlay {
  -webkit-mask-image: linear-gradient(
    to left,
    rgb(0, 0, 0) 0%,
    rgb(0, 0, 0) 12.5%,
    rgb(0, 0, 0) 87.5%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 50;
}

.left-arrow {
  color: white;
  transition: color 0.3s ease;
}

.right-arrow {
  transition: color 0.3s ease;
}
@layer utilities {

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; 
    scrollbar-width: none;
  }
}
 p{
  @apply text-[#6A6A6A]
}
.VJL48qbQzWENTFAh1Knk{
  display: none !important;
}